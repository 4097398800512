// src/templates/posts/LegacyTemplate.js

import React, {useEffect} from 'react'
import { graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import Layout from '../../components/Layout.js'
import PageTitle from '../../components/PageTitle'
import ResourceMetadata from '../../components/ResourceMetadata'
import ResourceCards from '../../components/ResourceCards.js'
import parse from 'html-react-parser'

function LegacyArticle({ data }) {
  const postData = data.wpArticle

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let postTags = []

      postData.tags.nodes.map((node, index) => (
        postTags.push(node.name)
      ))

      const gtmArgs = {
        pageType: 'article',
        publishDate: postData.date,
        tags: postTags,
      }

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(gtmArgs)
    }
  }, [postData, process.env.NODE_ENV])

  return (
    <Layout>
      <Seo post={postData} />

      <article className="space-y-responsive-xl-48">
        <div className="legacy-inner">
          <PageTitle
            label="Article"
            title={postData.title}
            url={postData.uri}
          />

          {postData.content && (
            <div className="container-inner">
              <div className="wysiwyg text space-y-responsive-xl-32">
                <p className="font-medium text-nav">{postData.date}</p>
                {parse(postData.content)}
              </div>
            </div>
          )}
        </div>

        <section className="related-articles">
          <div className="container">
            <h2 className="headline-md mb-responsive-xl-64 text-center">
              Related Resources
            </h2>
            <ResourceCards automatedResources={data.allWpArticle} />
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default LegacyArticle

export const query = graphql`
  query ($id: String!) {
    wpArticle(id: { eq: $id }) {
      seo {
        ...SeoFragment
      }
      uri
      title
      date(formatString: "M/D/YYYY")
      content
      tags {
        nodes {
          name
          slug
        }
      }
    }
    allWpArticle(
      filter: { id: { ne: $id } }
      limit: 3
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...ArticleResourceFragment
        }
      }
    }
  }
`
