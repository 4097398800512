import React from 'react'
import PropTypes from 'prop-types'
import DownloadSVG from '../../images/icon-download.svg'

const DownloadButton = ({ url, title }) => {
  return (
    <a
      href={url}
      title={title}
      target="_blank"
      rel="noopener noreferrer"
      className="btn btn-secondary"
    >
      <div className="flex items-center gap-responsive-xl-20">
        <p>Download</p>
        <DownloadSVG className="icon fill-white" />
      </div>
    </a>
  )
}

export default DownloadButton

DownloadButton.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
}
