import React from 'react'
import PropTypes from 'prop-types'
import OpenInNewTabSVG from '../../images/icon-open-in-new-tab.svg'

const AccessReportButton = ({ url }) => {
  return (
    <a
      href={url}
      title="Access the Report"
      target="_blank"
      rel="noopener noreferrer"
      className="btn btn-secondary"
    >
      <div className="flex items-center gap-responsive-xl-20">
        <p>Access the Report</p>
        <OpenInNewTabSVG className="icon fill-white" />
      </div>
    </a>
  )
}

export default AccessReportButton

AccessReportButton.propTypes = {
  url: PropTypes.string,
}
