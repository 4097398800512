import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import EmailSVG from '../images/icon-email.svg'
import TwitterSVG from '../images/icon-x.svg'
import LinkedinSVG from '../images/icon-linkedin.svg'
import DownloadButton from './Buttons/DownloadButton'
import AccessReportButton from './Buttons/AccessReportButton'

function SocialLinks(props) {
  // it's possible we could just grab current window url, but in an attempt to
  // keep the component composable, should we need to point the sharing to
  // something else, the relative url is passed in.

  const data = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          siteUrl
          title
        }
      }
    }
  `)

  const linkTitle = props.title ? encodeURIComponent(props.title) : 'Arete IR'
  const linkUrl = encodeURIComponent(data.site.siteMetadata.siteUrl + props.url)

  return (
    <div className="flex flex-col items-center justify-end gap-responsive-xl-20 space-x-responsive-xl-16 md:flex-row">
      <div className="flex flex-row items-center space-x-responsive-xl-16 ">
        <p className="text-xs font-regular uppercase tracking-wider">Share</p>
        <a
          href={`mailto:?subject=${linkTitle}&body=${linkUrl}`}
          className="bg-noise flex h-[50px] w-[50px] items-center justify-center rounded-full bg-light-gray transition-opacity hover:opacity-50"
          title="Email"
        >
          <EmailSVG className="icon fill-boynton-a11y" />
        </a>
        <a
          href={`https://twitter.com/intent/tweet/?text=${linkTitle}&url=${linkUrl}`}
          className="bg-noise flex h-[50px] w-[50px] items-center justify-center rounded-full bg-light-gray transition-opacity hover:opacity-50"
          title="Twitter"
          target="_blank"
        >
          <TwitterSVG
            style={{ width: '16px', height: '12px' }}
            className="icon fill-boynton-a11y"
          />
        </a>
        <a
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${linkUrl}`}
          className="bg-noise flex h-[50px] w-[50px] items-center justify-center rounded-full bg-light-gray transition-opacity hover:opacity-50"
          title="LinkedIn"
          target="_blank"
        >
          <LinkedinSVG className="icon fill-boynton-a11y" />
        </a>
      </div>

      <div className="flex flex-row items-center space-x-responsive-xl-16">
        {!props.hubSpotUrl && props.download && (
          <DownloadButton url={props.download} title={props.downloadTitle} />
        )}
        {props.hubSpotUrl && <AccessReportButton url={props.hubSpotUrl} />}
      </div>
    </div>
  )
}

export default SocialLinks

SocialLinks.propTypes = {
  download: PropTypes.string,
  downloadTitle: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
}
