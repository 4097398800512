// Very similar to ResourcesHeader but with
// - no underline
// - label element
// - smaller headline text
// Possible candidate for refactoring

import React from 'react'
import parse from 'html-react-parser'
import SocialLinks from './SocialLinks'
import cx from 'classnames'
import PropTypes from 'prop-types'

function PageTitle(props) {
  return (
    <section className="page-title">
      <div
        className={cx(
          'container-inner pt-responsive-xl-80 pb-responsive-xl-40 text-center',
          props.className
        )}
      >
        <p className="mb-responsive-xl-16 inline-block border-b border-fuscia-full pb-responsive-xl-4 text-nav font-medium uppercase tracking-widest">
          {props.label}
        </p>

        {props.title && (
          <h1 className="headline-lg mb-responsive-xl-16">
            {parse(props.title)}
          </h1>
        )}

        {props.description && (
          <p className="mt-responsive-xl-32 text-lg">{props.description}</p>
        )}
        {props.url ? (
          <SocialLinks
            title={props.title}
            url={props.url ? props.url : null}
            download={props.download ? props.download : null}
            downloadTitle={props.downloadTitle ? props.downloadTitle : null}
          />
        ) : null}
      </div>
    </section>
  )
}

export default PageTitle

PageTitle.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  download: PropTypes.string,
  downloadTitle: PropTypes.string,
  url: PropTypes.string,
}
