import React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import PropTypes from 'prop-types'

function ResourceMetadata(props) {
  return (
    <aside className="mt-responsive-xl-32 space-y-responsive-xl-20 px-responsive-xl-4 text-center">
      <div className="author-date flex items-baseline justify-center divide-x divide-granite-200">
        {props.author && (
          <p className="px-responsive-xl-8">
            <span className="mr-responsive-xl-4 align-baseline text-xs font-medium uppercase tracking-wider text-granite-600">
              Author:
            </span>
            <Link
              to={props.author.uri}
              className="align-baseline text-sm font-bold tracking-wider"
            >
              {props.author.bio.firstName} {props.author.bio.lastName}
            </Link>
          </p>
        )}
      </div>

      {props.relatedTopics && (
        <div className="related-topics space-y-4 sm:space-x-4 flex flex-col items-center justify-center gap-responsive-xl-8 sm:flex-row sm:space-y-0">
          {props.date && (
            <p className="px-responsive-xl-8 text-nav font-medium">
              {props.date}
            </p>
          )}
          <p className=" text-xs font-medium uppercase tracking-wider text-granite-600">
            Topics:
          </p>
          <div className="flex flex-wrap items-center justify-center gap-responsive-xl-4">
            {props.relatedTopics.map((node, index) => (
              <Link
                key={index}
                to={`/resources${node.uri}`}
                className="rounded-md border border-granite-600 bg-boynton-light py-responsive-xl-8 px-responsive-xl-12 text-nav font-medium text-fathom-400 no-underline "
              >
                {node.name}
              </Link>
            ))}
          </div>
        </div>
      )}
    </aside>
  )
}

export default ResourceMetadata

ResourceMetadata.propTypes = {
  author: PropTypes.object,
  relatedTopics: PropTypes.array,
  date: PropTypes.string,
}
